const bioContents = {
    gceo: {
        bioName: "Dr. Mohamed Farouk",
        bioPos: "Group CEO & Vice Chairman",
        bioDesc: `<p>
        Dr. Mohamed Farouk Mohamed Abdelmeguid Abdelkhalek has been a member of the ADES’s Board and has served as chief executive officer since 2012, during which time he has led the Company’s expansion into new markets and services as ADES transforms into a leading global rig operator headquartered in Al Khobar- Saudi Arabia since 2022.
      </p>
      <p>
      Dr. Farouk joined the Group from Invensys Operations Management (IOM), a FTSE 100 company, where he was most recently senior vice president for global delivery and operations based in Texas. He served earlier with Invensys as Director of Invensys Global Engineering Excellence Centers in Egypt, India, China and Argentina, prior to which he was the General Manager of Invensys Engineering and Services in Egypt. He began his career in 1991 as a Project Engineer at ConiSys Egypt, a provider of control and instrumentation systems technology.
      </p>
      <p>
      Dr. Farouk holds a PhD in systems engineering and control from Case Western Reserve University of Ohio, USA. He was subsequently an associate professor of electrical engineering at Cairo University. His past directorships include service to the board Schneider Electric for Process Systems; he is currently the Non-Executive Chairman of Schneider Electric Engineering and services Egypt, and Board Member of Rameda Pharmaceutical.
      </p>`
    },
    cfo: {
        bioName: "Hussein Badawy",
        bioPos: "Chief Financial Officer",
        bioDesc: `
        <p>
        Hussein Mohammed Abdul Salam Hassan Badawy joined ADES in 2016 and has
        been appointed as the group CFO since December 2021. Prior to his
        appointment as the group CFO, he held important roles as the Corporate
        Investor Relations and Finance Director.
      </p>
      <p>
        Bringing over more than 14+ years of experience, Mr. Hussein has played
        a pivotal role in ensuring sustainable capital growth and bringing
        financial stability to the group, through generating successful
        strategic investments that are backed up by strong Capital Structures.
        In addition to all Finance functions, Mr. Hussein spearheads our
        Strategic Planning and Decision Support that serves the group with
        profound strategies, analytics, and foresight. Prior to joining ADES,
        Mr. Hussein served as the Senior Relationship Manager for the Corporate
        & Investment Banking Group with Arab International Bank, a multilateral
        bank.
      </p>
      <p>
        Mr. Hussein has bachelor’s degree in International Business
        Administration from Sadat Academy for Management Sciences in
        collaboration with the university of New Brunswick – Canada and has a
        Professional Certification in Credit Risk & Risk Management from AUC
        School of Business.
      </p>`
    },

    gcla: {
        bioName: "Morcos Ekladious",
        bioPos: "Group General Counsel",
        bioDesc: `<p>
        Morcos joined ADES Group in July 2015 serving as General Counsel and Company Secretary. Prior to joining the group, he was the Head of Legal Affairs at Orascom Telecom Media and Technology Holding and Principal Lawyer for Mobinil (now Orange).
        </p>
        <p>
        Morcos brings almost 20 years of knowledge and experience in diverse business sectors including telecommunications, media, technology, real estate, maritime and oil and gas. His legal expertise varies from capital markets, restructuring, and acquisitions to financing projects. Morcos has gained recognition in his field for serving as the group General Counsel in relation to ADES's significant expansion. Over the past 8 years, he led the ADES Group legal team in many projects including listing and delisting from the London Stock Exchange, supporting the group operations across its multiple jurisdictions, and undertaking several group restructurings and loan syndications.  
        </p>
        <p>
        Morcos holds a Bachelor of Law and a Master of International Commercial Transactions and Logistics from the Arab Academy for Science and Technology.
        </p>`
    },
    vcades: {
        bioName: "Montaser Samy",
        bioPos: "Vice President - Saudi Arabia",
        bioDesc: `<p>
        As the Vice President & General Manager for KSA, Montaser Mohamed Samy
        Abdulrahman Naguib is heading the ADES business in the Kingdom of Saudi
        Arabia and is a key stake holder of the ADES leadership team. Mr.
        Montaser started his career in the field of Fracturing & Stimulation in
        Schlumberger and has held different leadership roles across Europe,
        Middle East, Russia & the US.
      </p>
      <p>
        Mr. Montaser’s last role in SLB was as the Global VP of Integrated
        Production Services based out of the UK. He has also held the roles of
        Vice President of Well Services for Middle East & Asia and Vice
        President of Deep water Well Integrity.
      </p>
      <p>
        Mr. Montaser has a bachelor’s degree in Mechanical Engineering from the
        American University in Cairo.
      </p>`
    },
    sherm: {
        bioName: "Mehdi Hamel",
        bioPos: "Global Director of Safety, Health, Environment and Risk Management",
        bioDesc: `<p>
        Mehdi Hamel joined ADES in March 2022 as the Global Director of Safety,
        Health, Environment and Risk Management; where in his capacity he played
        a pivotal role in setting strong fundamentals of culture of Quality,
        Health, Safety, Security, Environment and Sustainability across our
        global footprint.
      </p>
      <p>
        Mr. Mehdi has over 20 plus years of professional experience and has
        worked across different geo markets in Europe, Middle East and Africa.
        Mr. Mehdi has held several senior positions in large oil and gas
        corporations such as WesternGeco & Schlumberger in QHSSE & Supply Chain
        functions.
      </p>
      <p>
        Mr. Mehdi has led different strategic initiatives during his tenure that
        led to significant improvement in QHSSE performance. Mr. Mehdi has his
        Engineering degree in Geophysics and Petrophysics from Houari Boumediene
        University and has an MBA in Petroleum Engineering from Heriot Watt
        University.
      </p>`
    },
    ccso: {
        bioName: "Ahmed Abdelhady",
        bioPos: "Chief Commercial and Strategy Officer",
        bioDesc: `<p>
        Ahmed Mohamed Abdel Hady Osman joined ADES Group in May 2022 as the
        Chief Commercial and Strategy Officer with responsibilities for defining
        and implementing the Group’s business objectives and strategies as well
        as leading the overall marketing efforts for the Group’s fleet. In his
        current role, Mr. Ahmed is heading the Commercial and Strategy
        functions. Prior to joining ADES Group, Mr. Ahmed spent more than 10
        years with Shelf Drilling in Dubai, United Arab Emirates where he most
        recently served as Director of Marketing for the Middle East, Europe and
        North Africa regions.
      </p>
      <p>
        Mr. Ahmed brings an excess of twenty years of industry experience having
        assumed senior managerial and leadership roles in Marketing & Contracts,
        Drilling Operations, Projects, Asset Management & QHSE with other
        industry leading names like legacy GlobalSantaFe and Transocean. Ahmed
        started his offshore drilling career in SantaFe back in 2001.
      </p>
      <p>
        Mr. Ahmed holds a bachelor’s degree in Civil Engineering from Cairo
        University with Project Management studies.
      </p>`
    },
    vcam: {
        bioName: "Mohammed Saad",
        bioPos: "Vice Chairman of the Company for Asset Management",
        bioDesc: `<p>
        Mohammed Saad Khalil Al Sira joined ADES Group since September 2015 as
        Regional Asset & Maintenance Director and currently assumes the role of
        Asset Management Vice President since October 2022. Since joining, he
        continued capitalizing on the steady and multi-disciplinary engineering
        activities and the successful technical and maintenance strategies that
        ensure successful operating company assets, setting of premium processes
        & quality standards across our various activities and fleet of Offshore
        Jack-up Rigs, Onshore Rigs, Barge, MOPU and FSO.
      </p>
      <p>
        Mr. Mohammed has a wealth professional record of 13+ years of solid
        experience and technicalities assuming multiple roles with an industry
        big name as Transocean, where he successfully kicked off his career as a
        Tour Mechanic in Transocean-Egypt and led ahead a fast-growing career
        till his last appointment as Asset Manager Transocean – Malta in 2015.
        Mohamed is a Bachelor of Mechanical Power Engineering where he holds
        several technical certifications.
      </p>`
    },
    cpo: {
        bioName: "Manoj Parmesh",
        bioPos: "Chief Human Resources Officer",
        bioDesc: `<p>
        Manoj Ganesh Parmesh joined ADES Group in January 2023 as the Chief
        People Officer and is heading the People and Learning functions. Mr.
        Manoj has over two and half decades of experience in the field of
        Strategic Human Resources, Capability building, large scale people
        mobilization, organization development and competency development
        programs.
      </p>
      <p>
        Mr. Manoj has an excellent understanding of the upstream oil & gas
        industry having worked with E & P, Oil Field Services and Drilling
        Contractors. Mr. Manoj has worked in different geographies in Asia,
        Middle East & North Africa and has held HR leadership roles in Reliance
        Industries Offshore E & P Business, Weatherford International’s Drilling
        Rigs business. He also worked at C-Suite level for Muscat based listed
        company Renaissance Services.
      </p>
      <p>
        Mr. Manoj is an alumnus of MIT Sloan and Indian Institute of Management
        Kozhikode (IIM-K) and is a Chartered Fellow of CIPD of the UK. Mr. Manoj
        is also actively involved in the professional forums of IADC, SPE and
        CIPD.
      </p>`
    },
    cito: {
        bioName: "Wissam Al Adany",
        bioPos: "Chief Information Officer",
        bioDesc: `<p>

        Wissam Hamed Morsi Ibrahim Al Adany joined ADES Group as the Chief
        Information Officer in February 2022, bringing over 27 years of profound
        experience in digital transformation within multinational organizations,
        having spent over 4 years in Brazil and Kazakhstan spearheading digital
        transformation efforts of these organizations. Prior to joining ADES,
        Mr. Wissam has served as the Regional CIO for both the United Nations in
        Eastern Europe, Central Asia, and MENA regions, as well as Lafarge and
        GB Auto as the industry’s renowned names in both building material and
        automotive respectively.
      </p>
      <p>

        In recognition of his outstanding contributions, Mr. Wissam has been
        honored with several awards, including the CIO200 award as a Legend CIO
        in Africa and MENA for both 2021 and 2022, the best CIO of Automotive in
        MENA by the Global CIO Forum, and awarded from IDC consecutively as the
        "CIO of the Year" in 2021 and 2020 and from top 20 CIOs in 2023. Mr.
        Wissam holds a master’s degree in Business Administration from the
        American University in Cairo (AUC) and a Bachelor of Communication and
        Electronics Engineering.
      </p>`
    },
    dscm: {
        bioName: "Ammar Abbas",
        bioPos: "Vice President - Supply Chain Management",
        bioDesc: `<p>
        Ammar Abbas has recently joined ADES Group as the Vice President of
        Supply Chain, with an extensive career spanning 25 years.
      </p>
      <p>
        Mr. Ammar boasts rich expertise in Procurement, Strategic Sourcing,
        Logistics & Customs, Material Management, Inventory Management, Global
        Traceability, Product Planning, and Sales & Operations Planning (S&OP).
        His comprehensive mastery of supply chain dynamics extends to the
        intricacies of the Oilfield industry, covering both its technical facets
        and geographical complexities. His professional journey includes senior
        leadership roles in the supply chain domain across significant regions,
        namely MEA, Europe, Africa, and North America, where he contributed
        significantly during his tenures at SLB. Furthermore, he held pivotal
        Champion and Executive Supply Chain positions at NESR.
      </p>
      <p>
        Mr. Ammar Holds a bachelor’s degree in Economics, Finance and business
        administration from Aleppo University in Syria. Mr. Ammar's commitment
        to excellence is underscored by his acquisition of esteemed
        certifications in Supply Chain training from reputable institutions in
        Europe and the USA. Mr. Ammar also obtained training certification in
        strategic executive Supply chain management from MIT.
      </p>`
    },
};


window.validationFunction = function validationFunction(event) {
  event.preventDefault();
  var div;
  div = document.getElementById("appendedText");
  div.innerHTML += "hello world";
}

window.openModalLeaders=function openModalLeaders(bioId) {
    const modal = document.getElementById('dynamicModal');
    //const modalContent = document.getElementById('modalContent');
    const bioNames = document.getElementById('bioNames');
    const bioPos = document.getElementById('bioPos');
    const bioDesc = document.getElementById('bioDesc');
    
    // if (modalContents[modalId]) {
    //     const content = modalContents[modalId];
    //     document.getElementById('modalTitle').textContent = content.title;
    //     modalContent.innerHTML = content.content;
    //     modal.showModal();
    // }

    if (bioContents[bioId]) {
        const content = bioContents[bioId];
        // document.getElementById('modalTitle').textContent = content.bioName;
        bioNames.textContent = content.bioName;
        bioPos.textContent = content.bioPos;
        bioDesc.innerHTML = content.bioDesc;
        modal.showModal();
        // Prevent scrolling
        //document.body.style.scrollBehavior = 'none';
        }
}

function closeModalModalLeaders() {
    const modal = document.getElementById('modal');
    modal.close();
     // Restore scrolling
     //document.body.style.scrollBehavior = 'auto';

    }